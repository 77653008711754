
import SpSepInputContainer from "../FormComponents/SpSepInputContainer.vue";
import LocalService from "@/services/LocalService";
import { Venue } from "@/types";
import { defineComponent } from "vue";
import dayjs from "dayjs";
import { POSITION, TYPE, useToast } from "vue-toastification";

export default defineComponent({
  async mounted() {
    await this.lookupVenuesAndSpecials();
  },
  data() {
    return {
      venues: [],
      googlePlaceIdToLoadFromDB: "",
      name: "",
      description: "",
      address: "",
      lat: null,
      long: null,
      googlePlaceId: "",
      website: "",
      phone: "",
      venueTags: [],
      forcedTags: [],
      showEnterButtonMessage: false,
      errorMessage: "",
      yelpPlaceId: "",
      instagramHandle: "",
      venueId: "",
    };
  },
  methods: {
    dayjs,
    async setGoogleId(v: Venue) {
      this.googlePlaceId = v.googlePlaceId;
      this.venueId = v.id;
      await this.checkPlaceId();
    },
    async lookupVenuesAndSpecials() {
      //   const r = await LocalService.lookupVenuesAndSpecials();
      try {
        const r = await LocalService.lookupVenueIds(undefined);
        this.venues = r?.data?.details;
      } catch (e) {
        console.log(e);
      }
    },
    async checkPlaceId() {
      if (this.googlePlaceId !== "") {
        const r = await LocalService.lookupVenueDetailsByGooglePlaceId(
          this.googlePlaceId
        );
        const deets = r.data.details;
        const type = r.data.type;
        if (type === "db" && deets) {
          this.forcedTags = [];
          this.name = deets.name;
          this.description = deets.description;
          this.address = deets.address;
          this.lat = deets.lat;
          this.long = deets.long;
          this.website = deets.website;
          this.phone = deets.phoneNumber;
          this.forcedTags = deets.tags;
          this.yelpPlaceId = deets.yelpPlaceId;
          this.instagramHandle = deets.instagramHandle;
        } else if (type === "google" && deets) {
          this.name = deets.name ?? "";
          this.address = deets.vicinity ?? "";
          this.lat = deets.geometry?.location?.lat ?? null;
          this.long = deets.geometry?.location?.lng ?? null;
          this.website = deets.website ?? this.website;
          this.phone = deets.formatted_phone_number ?? this.phone;
        }
        console.log(r.data.details);
      }
    },
    typing() {
      this.showEnterButtonMessage = true;
    },
    ended() {
      this.showEnterButtonMessage = false;
    },
    updateTags(i: string[]) {
      this.venueTags = JSON.parse(JSON.stringify(i));
    },
    async deleteVenue() {
      const toast = useToast();
      const confirmed = confirm(
        "Are you sure you want to delete " + this.name + "?"
      );
      if (confirmed) {
        if (this.venueId) {
          const r = await LocalService.deleteVenue(this.venueId);
          if (r.status === 200) {
            toast(r.data.msg, {
              timeout: 5000,
              type: TYPE.INFO,
              hideProgressBar: true,
              position: POSITION.TOP_LEFT,
            });
          } else {
            toast("Failed to delete venue", {
              timeout: 5000,
              type: TYPE.ERROR,
              hideProgressBar: true,
              position: POSITION.TOP_LEFT,
            });
          }
        } else {
          toast("Error. Click a venue first, then delete it.", {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
            position: POSITION.TOP_LEFT,
          });
        }
      } else {
        toast("Venue was NOT deleted", {
          timeout: 5000,
          type: TYPE.INFO,
          hideProgressBar: true,
          position: POSITION.TOP_LEFT,
        });
      }
    },
    async saveVenue() {
      try {
        if (
          !this.name ||
          !this.description ||
          !this.address ||
          this.lat === null ||
          this.long === null ||
          !this.googlePlaceId ||
          !this.phone ||
          this.venueTags.length === 0 ||
          !this.yelpPlaceId
        ) {
          const toast = useToast();
          toast("Missing required fields", {
            timeout: 5000,
            type: TYPE.ERROR,
            position: POSITION.TOP_LEFT,
            hideProgressBar: true,
          });
        } else {
          const r = await LocalService.createVenue(
            this.name,
            this.description,
            this.address,
            this.lat,
            this.long,
            this.googlePlaceId,
            this.website,
            this.phone,
            this.venueTags,
            this.yelpPlaceId,
            this.instagramHandle ?? null
          );
          if (r.status === 200) {
            const toast = useToast();
            toast(
              r.data.msg +
                " Venue Id: " +
                r.data.id +
                "\n Window will reload...",
              {
                timeout: 5000,
                type: TYPE.SUCCESS,
                hideProgressBar: true,
                position: POSITION.TOP_LEFT,
              }
            );
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            const toast = useToast();
            toast("Failed to create venue", {
              timeout: 5000,
              type: TYPE.ERROR,
              hideProgressBar: true,
              position: POSITION.TOP_LEFT,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  components: { SpSepInputContainer },
});
